
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCUV9qEvEj2dVX5U9iSSEX0sJ1bvVb6eVc",
  authDomain: 'seminar-e9e00.firebaseapp.com',
  projectId: "seminar-e9e00",
  storageBucket: "seminar-e9e00.appspot.com",
  messagingSenderId: "228401427109",
  appId: "1:228401427109:web:b326aeeef83a5a3bb1534c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export {db,auth}