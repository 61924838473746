import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom'
import {auth} from './components/config/firebaseConfig'
import {onAuthStateChanged} from 'firebase/auth'
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Dashboard from './components/Dashboard';
const App = () => {
  const [authUser, setAuthUser] = useState(false);
  useEffect(() => {
    onAuthStateChanged(auth,(user) => {
      if (user) {
        // signed in
        setAuthUser(true)
      } else {
        setAuthUser(false)
      }
    })
  })
  
  return (
    <div>
      {authUser ? (
        <Dashboard />
      ) : (
        <Router>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
      </Router>

      )}
    </div>
  );
}
export default App;