import React, { useEffect, useState } from "react";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./config/firebaseConfig";

import "./css/seminars.css";
import { onSnapshot, doc, getDocs, collection,updateDoc,arrayUnion } from "firebase/firestore";
import Card from "./dashSubComponents/Card";
const Dashboard = () => {
  const handleSignOut = () => {
    signOut(auth);
  };
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nameA, setNameA] = useState("");
  const [address, setAddress] = useState("");
  const [id, setId] = useState("");
  const [payed, setPayed] = useState("");
  const [seminars, setSeminars] = useState([]);


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        onSnapshot(doc(db, "users", user.email), (doc) => {
          setPhone(doc.data().phone);
          setAddress(doc.data().address);
          setNameA(doc.data().name);
          setId(doc.data().id)
          setPayed(doc.data().payed)
          setEmail(user.email)
        });
      } else {
        setEmail("");
      }
    });
  },[]);
  useEffect(
    () =>
      onSnapshot(collection(db, "seminars"), (snapshot) => {
        setSeminars(snapshot.docs.map((doc) => doc.data()));
      }),
    []
  );
  const onSucess = async(orderId) => {
    await updateDoc(doc(db,"users",email),{
        payed:arrayUnion("payed-"+orderId)
    }).then(() => {
        alert("Payed Successfully")
    }).catch((err) => {
        alert("Error has been occured")
    })
  }
 
  const onDismissed = () => {
    alert("Dismissed Payment");
  };
  const onError = (error) => {
    alert(error);
  };
  return (
    <div className="dashboard">
      <h1 className="title-dash">Dashboard</h1>
      <button className="btn-logout" onClick={handleSignOut}>
        Sign Out
      </button>
      <div className="dashFlex">
        {
            seminars &&
          
            seminars.map((data) => {
            return (
              <Card
                title={data.title}
                desc={data.desc}
                time={data.time}
                date={data.date}
                onSucess={onSucess}
                onError={onError}
                onDismissed={onDismissed}
                email={email}
                phone={phone}
                address={address}
                name={nameA}
                id={data.id}
                seminarName={data.seminarName}
                payed={payed}
                amount={data.amount}
                tute={data.tute}
                accName={data.accName}
                accNumber={data.accNumber}
                bankName={data.bankName}
                seminarSupport={data.seminarSupport}
                branchName={data.branchName}
                linkToJoin={data.linkToJoin}
              />
            );
          })}
      </div>
 
    </div>
  );
};

export default Dashboard;
