import React, { useEffect,useState } from "react";
import { PayHereButton } from 'react-payhere-button'

const Card = (props) => {
    const [payedAccess, setPayedAccess] = useState(false);
    const {title,desc,time,date,onSucess,onDismissed,onError,id,name,amount,seminarName,email,phone,address,tute,accName,accNumber,bankName,seminarSupport,branchName,payed,linkToJoin} = props
    useEffect(() => {
        
     if (payed.includes('payed-'+ id)) {  
        setPayedAccess(true)
     } else {
         setPayedAccess(false)
     }   
    })
  return (
    <div className="dashCard">
      <h1 className="dash-card-title">{title}</h1>
      <br />
      <p className="dash-card-text">{desc}</p>
      <br />
      <p className="dash-card-text">මුදල් තැන්පත් කිරීම්/තාක්ෂණික සහය සදහා විමසන්න -{seminarSupport}</p>
      <br />
      <ul>
        <li className="dash-card-list">දිනය - {date}</li>
        <li className="dash-card-list">වේලාව - {time}</li>
        <li className="dash-card-list">ගාස්තුව - {amount}/=</li>
        <li className="dash-card-list">නිබන්ධන - {tute}</li>
      </ul>
      <br />
      <h3>මුදල් තැන්පත් කිරීම් සදහා</h3>
      <ul>
      <li className="dash-card-list">ACCOUNT NAME - {accName}</li>
      <li className="dash-card-list">ACCOUNT NUMBER - {accNumber}</li>
      <li className="dash-card-list">BANK NAME - {bankName}/=</li>
      <li className="dash-card-list">BRANCH NAME - {branchName}</li>

    </ul>
    {
    payedAccess ? (
        <a href={linkToJoin}>{linkToJoin}</a>
    ):(
        <PayHereButton
        merchant_id={'216521'}
        onCompleted={onSucess}
        onDismissed={onDismissed}
        onError={onError}
        order_id={id}
        items={seminarName}
        amount={amount}
        currency={'LKR'}
        first_name={name}
        last_name={'Online'}
        email={email}
        phone={phone}
        address={address}
        city={'Colombo'}
        country={'Sri Lanka'}
        options={{
          delivery_address: address,
          delivery_city: '',
          delivery_country: '',
          custom_1: '',
          custom_2: '',
          return_url: '',
          cancel_url: '',
          notify_url: '',
        }}
      />
    )
    }
    



 
    </div>
  );
};

export default Card;
